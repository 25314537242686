




























































































































































































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { blackList } from '@/util/blackFair';
import { ElForm } from 'element-ui/types/form';
import { mapState } from 'vuex';
import { ResponseCode } from '~/util/constant';
import { getXOssProcess, message } from '~/util/common';
import { bucketCdnHost, bucketHost } from '~/util/common';
import { TraceCode } from '~/util/trace';
import { $cookies } from '~/util/api';
import { serviceObj } from '~/pages/fair/service';

export default Vue.extend({
  name: 'Booth',
  layout: 'layout2',
  components: {},
  async asyncData(ctx) {
    const { type = 'booth' } = ctx.query;
    const id = _.get(ctx, 'params.id', null);

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.post(`/api/followeApi/getExhibitionFollowedUsers`, {
        exhibitionId: id,
        pageNum: 1,
        pageSize: 12,
      }),
    ]);

    const [fairRes, followRes] = res;

    let fair: Record<string, any> = {};
    let remind = false; // 是否提醒
    if (fairRes.code === ResponseCode.SUCCESS) {
      if (fairRes.data.boothInfo) {
        fairRes.data.boothInfo = JSON.parse(fairRes.data.boothInfo);
      } else {
        fairRes.data.boothInfo = [];
      }
      fair = fairRes.data || {};
      fair.boothPrice1 = '';
      fair.boothPrice2 = '';
      remind = fairRes.data.follow; // 1: 提醒 0: 未提醒
      if (fair.boothInfo && fair.boothInfo.length > 0) {
        fair.boothInfo.forEach((item: any) => {
          if (item.priceType === '标准展位') {
            fair.boothPrice1 = item.price;
          }
          if (item.priceType === '光地展位') {
            fair.boothPrice2 = item.price;
          }
        });
      }
    }

    const follow: { data: any[]; total: number } = {
      data: [],
      total: 0,
    };
    if (followRes.code === ResponseCode.SUCCESS) {
      follow.data = followRes.data?.records || [];
      follow.total = followRes.data?.total || 0;
    }
    return {
      fair,
      type,
      follow,
      remind,
    };
  },
  data(): Record<string, any> {
    return {
      blackList,
      fair: {},
      booth: {},
      isLoading: false,
      pageNum: 1,
      messageList: [],
      headimg: require('@/assets/images/headimg.jpg'),
      userVisible: false,
      ruleForm: {
        companyName: '',
        companyOfficialWebsite: '',
        email: '',
        productNames: [{ value: '' }],
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        productNames: [
          { required: true, message: '公司产品不能为空', trigger: 'blur' },
        ],
      },
      userId: 0,
      serviceList: [],
      boothServiceList: [],
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  created() {
    this.getOrderMessage();
    this.getService();
  },
  mounted() {
    window.trace?.upload({
      module: TraceCode.MO_COMMON,
      callbackCode: TraceCode.CC_FAIR_DETAIL,
      bizId: this.$route.params.id,
    });
    const industry = this.fair.firstIndustryId;
    this.serviceList = serviceObj[industry]?.service || [];
    if (this.fair.industry === '消费电子') {
      Vue.set(this, 'serviceList', this.serviceList.slice(1));
    } else {
      Vue.set(
        this,
        'serviceList',
        this.serviceList.slice(0, 1).concat(this.serviceList.slice(2))
      );
    }
  },
  methods: {
    getXOssProcess,
    // 获取客服
    async getService() {
      const res: any = await this.$axios.post(`/api/hr/staff/services`, {
        exhibitionId: this.fair.exhibitionId,
        industry: this.fair.industry1,
        skillCodes: ['exOnlineSale'],
      });
      if (res.code === ResponseCode.SUCCESS) {
        
        let list = res.data;
        list.map((item: any) => {
          item.avatar = `${item.avatar}?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_70,h_70/sharpen,100`;
        });
        this.boothServiceList = res.data || [];
      }
    },
    async getOrderMessage() {
      const id = _.get(this, '$route.params.id', null);
      if (!id) return;
      const res = await this.$axios.post(
        `/api/todayMessagesApi/getMessages/${id}`
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.messageList = res.data || [];
      }
    },
    async handleFollowChange(val: number) {
      if (val > 1 && !this.isLogin) {
        this.$login.show();
        this.pageNum = 1;
        return;
      }
      this.isLoading = true;
      const res = await this.$axios.post(
        `/api/followeApi/getExhibitionFollowedUsers`,
        {
          exhibitionId: this.fair.exhibitionId,
          pageNum: val,
          pageSize: 12,
        }
      );
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        this.$set(this, 'follow', {
          data: res.data.records,
          total: res.data.total,
        });
        this.pageNum = val;
      }
    },
    async handleToTicket() {
      if (this.fair.thirdUrl) {
        const token = this.$cookies.get('access_token');
        if (!token) {
          await this.$store.commit(
            'user/setOrigins',
            location.origin + this.$route.fullPath
          );
          await this.$store.commit('user/setAction', 2);
          await this.$store.commit('user/setIndustryId', this.fair.industry1);

          this.$login.show();
        } else {
          window.trace?.upload({
            module: TraceCode.MO_FAIR_TICKET,
            bizId: this.$route.params.id,
            callbackCode: TraceCode.CC_TICKET_OUT,
          });
          window.open(this.detail.thirdUrl, '_blank');
        }
      } else {
        window.trace?.upload({
          module: TraceCode.MO_FAIR_TICKET,
          bizId: this.$route.params.id,
          callbackCode: TraceCode.CC_TICKET_IN,
        });
        this.$windowOpen(
          `/ticket/${this.detail.exhibitionId}.html`,
          '_blank'
        );
      }
    },
    handleBuy(tag: number) {
      this.$windowOpen(
        `/ticket/${this.$route.params.id}.html`,
        '_blank'
      );
    },
    handleApply(tag: number) {
      this.$windowOpen(
        `/exhibition/apply/${this.$route.params.id}.html`,
        '_blank'
      );
    },
    readPeriodical() {
      this.$windowOpen(`/periodical/${this.$route.params.id}.html`, '_blank');
    },
    // 展会提醒
    async handleRemind() {
      if (this.shouldLogin()) {
        const id = _.get(this, '$route.params.id', null);
        if (!/\d+/.test(id)) {
          message.call(this, {
            delay: 3000,
            title: `展会Id:${id}不合法!`,
            type: 'error',
          });
          return;
        }
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        if (res.code === ResponseCode.SUCCESS) {
          this.remind = !this.remind;
          const title = !this.remind ? '取消通知成功!' : '添加通知成功!';
          message.call(this, {
            delay: 3000,
            title,
            type: 'success',
          });
          // this.$toast(!this.remind ? '取消通知成功!' : '添加通知成功!', 3000);
        }
      }
    },
    getBusiness(tag: any) {
      if (tag === 'visa') {
        this.$router.push(`/visa-0-0-0-0-1/`);
      } else if (tag === 'exhibition') {
        this.$windowOpen(
          `/exhibition/${this.fair.exhibitionId}.html`,
          '_blank'
        );
      } else {
        window.open(`${this.$config.HOTEL}`, '_self');
      }
    },
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    async toUser(id: number) {
      if (this.shouldLogin()) {
        this.userId = id;
        const res: any = await this.$axios.post(
          `/api/followeApi/checkFollowUserInfo`
        );
        if (res.code === '16002') {
          this.userVisible = true;
        } else {
          window.open(`/expouser/${id}.html`);
        }
        // this.userVisible = true;
      }
    },
    addDomain() {
      this.ruleForm.productNames.push({
        value: '',
      });
    },
    removeDomain(idx: number) {
      this.ruleForm.productNames.splice(idx, 1);
    },
    resetForm() {
      const $form = this.$refs.rule as ElForm;
      $form?.resetFields();
      this.userVisible = false;
    },
    submit() {
      const form = this.$refs.rule as ElForm;
      form.validate((valid) => {
        if (valid) {
          this.fecthSave();
        } else {
          console.log('error submit!!');
        }
      });
    },
    async fecthSave() {
      this.ruleForm.productNames = _.cloneDeep(
        this.ruleForm.productNames.map((item: any) => item.value)
      );
      const res: any = await this.$axios.post(
        '/api/followeApi/updateFollowUserInfo',
        {
          ...this.ruleForm,
        }
      );
      if (res.code === '1') {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        this.resetForm();
        setTimeout(() => {
          window.open(`/expouser/${this.userId}.html`);
        }, 1000);
      }
    },
  },
  head(): Record<string, any> {
    const { exhibitionName, area } = this.$data.fair;
    let title = `${exhibitionName}_用户社区-聚展`;
    let keywords = `${exhibitionName}_用户社区-聚展`;
    let description = `${exhibitionName}_用户社区-聚展`;
    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
});
