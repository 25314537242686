


























































































































































































































































import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import AsideFair from '~/components/AsideFair/index.vue';
import { message } from '~/util/common';

export default Vue.extend({
  name: 'Booth',
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
  components: {
    AsideFair,
  },
  async asyncData(ctx) {
    const id = _.get(ctx, 'params.id', null);

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.get(`/api/exhibition/boothInfo/${id}`),
    ]);

    const [fairRes, boothRes] = res;

    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data;
    }

    // 展会价格
    let booth = {};
    if (boothRes.code === ResponseCode.SUCCESS) {
      booth = boothRes.data;
    }

    return {
      fair,
      booth,
    };
  },
  data(): Record<string, any> {
    // const checkEmail = (
    //   _rule: any,
    //   value: string,
    //   callback: (msg?: string) => void
    // ) => {
    //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    //   if (!mailReg.test(value)) {
    //     callback('请输入正确的邮箱格式');
    //   } else {
    //     callback();
    //   }
    // };

    // const checkTel = (
    //   _rule: any,
    //   value: string,
    //   callback: (msg?: string) => void
    // ) => {
    //   const telReg = /^[\d]{7,16}$/;
    //   if (!telReg.test(value)) {
    //     callback('请输入正确的电话格式');
    //   } else {
    //     callback();
    //   }
    // };

    return {
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: '1',
        linkmanPhoneNumber: '',
        content: '',
        scale: '',
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        // email: [
        //   { required: true, message: '邮箱不能为空', trigger: 'blur' },
        //   // { validator: checkEmail, trigger: 'blur' },
        // ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          // { validator: checkTel, trigger: 'blur' },
        ],
        content: [
          { required: true, message: '展品内容不能为空', trigger: 'blur' },
        ],
        scale: [
          { required: true, message: '展位面积不能为空', trigger: 'blur' },
        ],
      },
      fair: {},
      booth: {},
      isLoading: false,
    };
  },
  created() {
    if (process.client) {
      const boothForm = sessionStorage.getItem('boothForm');
      try {
        this.ruleForm = JSON.parse(boothForm as any) || {};
      } catch (error) {
        console.error(error);
      }
      if (boothForm) {
        sessionStorage.removeItem('boothForm');
      }
    }
  },
  mounted() {
    const phone = this.$cookies.get('phoneNumber') || '';
    this.$set(this.ruleForm, 'linkmanPhoneNumber', phone);
  },
  methods: {
    submitForm(formName: string) {
      const $form = this.$refs[formName] as ElForm;
      $form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.handleApplyBooth();
        } else {
          return false;
        }
      });
    },
    async handleApplyBooth() {
      const { id } = this.$route.params;
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 1);
      //   await this.$store.commit('user/setIndustryId', this.fair.industry1);
      //
      //   this.$login.show();
      //   return;
      // }
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: +id,
        promotionLink: window.location.href,
      });
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '展位申请成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
        this.$router.push(`/exhibition/${id}.html`);
        // this.$message.success('展位申请成功.');
      }
    },
  },
  head() {
    return {
      title: `${this.$data.fair.exhibitionName}_展位预订-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
      ],
    };
  },
});
